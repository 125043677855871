<template>
    <div>
        <footer class="site-footer">
                <div class="container">
                    <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-8">
                                <h2 class="footer-heading mb-4">About Us</h2>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque facere laudantium magnam voluptatum autem. Amet aliquid nesciunt veritatis aliquam.</p>
                            </div>
                            <div class="col-md-4 ml-auto">
                                <h2 class="footer-heading mb-4">Features</h2>
                                <ul class="list-unstyled">
                                <li><a href="#mission-section">Биз жөнүндө</a></li>
                                <li><a href="#press-section">Соңку маалыматтар</a></li>
                                <li><a href="#testimonials-section">Отзывдар</a></li>
                                <li><a href="#exam-section">ЖРТ</a></li>
                                <li><a href="#faq-section">Служба поддержки</a></li>
                                <li><a href="#english-section">English language</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 ml-auto">
                        <div class="mb-5">
                            <h2 class="footer-heading mb-4">Subscribe to Newsletter</h2>
                            <form action="#" method="post" class="footer-suscribe-form">
                                <div class="input-group mb-3">
                                <input type="text" class="form-control border-secondary text-white bg-transparent" placeholder="Enter Email" aria-label="Enter Email" aria-describedby="button-addon2">
                                <div class="input-group-append"><button class="btn btn-primary text-white" type="button" id="button-addon2">Subscribe</button></div>
                                </div>
                                <h2 class="footer-heading mb-4">Follow Us</h2>
                                <a href="https://www.facebook.com/zhyldyz.academy" target="_blank" class="smoothscroll pl-0 pr-3"><span class="icon-facebook"></span></a><a href="https://www.instagram.com/zhyldyz_academy/" target="_blank" class="pl-3 pr-3"><span class="icon-instagram"></span></a><a href="https://wa.me/+996704949501" target="_blank" class="pl-3 pr-3"><span class="icon-whatsapp"></span></a>
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
            </footer>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
 .v-application ul, .v-application ol {
    padding-left: 0px !important; 
}   
.v-application a {
    color: #ffffff !important;
}
.v-application a:hover {
    color: #00ffea !important;
}
</style>