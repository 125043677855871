<template>
    <div>
            <div class="site-mobile-menu site-navbar-target">
                <div class="site-mobile-menu-header">
                    <div class="site-mobile-menu-close mt-3">
                    <span class="icon-close2 js-menu-toggle"></span>
                    </div>
                </div>
                <div class="site-mobile-menu-body">
                    <ul class="site-nav-wrap">
                    <li class="has-children">
                        <span class="arrow-collapse collapsed" data-toggle="collapse" data-target="#collapseItem0"></span>
                        <a href="#" class="nav-link">Главная<v-icon>mdi-menu</v-icon></a>
                        <ul class="collapse" id="collapseItem0">
                            <li>
                                <a href="/#mission-section" class="nav-link">Биз жөнүндө</a>
                            </li>
                            <li>
                                <a href="/#press-section" class="nav-link">Соңку маалыматтар</a>
                            </li>
                            <li>
                                <a href="/#testimonials-section" class="nav-link">Отзывдар</a>
                            </li>
                        </ul>
                    </li>
                    <li><a href="#english-section" class="nav-link">English language</a>
                    </li>
                    <li><a href="#exam-section" class="nav-link">ЖРТ</a></li>
                    <li><a href="#actual-section" class="nav-link">Актуладуу маалыматтар</a></li>
                    <li><a href="#faq-section" class="nav-link">Служба поддержки</a></li>
                    </ul>
                </div>
            </div>
            <div class="top-bar">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <a href="mailto:Zhyldyzacademy@protonmail.com" class="text-white">
                                <span class="mr-2 text-white icon-envelope-open-o"><v-icon color="#fff">mdi-gmail</v-icon></span> 
                                <span class="d-none d-md-inline-block">Zhyldyzacademy@protonmail.com</span>
                            </a>
                            <span class="mx-md-2 d-inline-block"></span>
                            <a href="#" @click="window.open('tel:+996555949501 ')" class="text-white">
                                <span class="mr-2 text-white icon-phone"><v-icon color="#fff">mdi-phone</v-icon></span>
                                <span class="d-none d-md-inline-block">+996 555 94 95 01 </span>
                            </a>
                            <div class="float-right">
                                <a href="https://www.facebook.com/zhyldyz.academy" target="_blank" class="text-white">
                                    <span class="mr-2 text-white icon-facebook"><v-icon color="#fff">mdi-facebook</v-icon></span>
                                    <span class="d-none d-md-inline-block">Facebook</span>
                                </a>
                                <span class="mx-md-2 d-inline-block"></span>
                                <a href="https://www.instagram.com/zhyldyz_academy/" target="_blank" class="text-white">
                                    <span class="mr-2 text-white icon-instagram"><v-icon color="#fff">mdi-instagram</v-icon></span>
                                    <span class="d-none d-md-inline-block">Instagram</span>
                                </a>
                                <span class="mx-md-2 d-inline-block"></span>
                                <a href="https://wa.me/+996704949501" target="_blank" class="text-white">
                                    <span class="mr-2 text-white icon-whatsapp"><v-icon color="#fff">mdi-whatsapp</v-icon></span>
                                    <span class="d-none d-md-inline-block">WhatsApp</span>
                                </a>
                                <a href="https://wa.me/+996704949501" target="_blank" class="text-white" @click="logOut">
                                    <span class="mr-2 text-white icon-whatsapp" ><v-icon color="#fff" style="border: 1px solid white;border-radius:50%; padding:4px;">mdi-account</v-icon> {{loggedIn.displayName}}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="sticky-wrapper" class="sticky-wrapper" >
                <header class="site-navbar js-sticky-header site-navbar-target" role="banner" >
                    <div class="container">
                    <div class="row align-items-center position-relative">
                        <div class="site-logo"><img src="../../assets/img/logo.png" alt=""><a href="#home-section" class="text-black"><span class="text-primary">Zhyldyz Academy</span></a></div>
                        <div class="col-12">
                            <nav class="site-navigation text-right ml-auto " role="navigation">
                                <ul class="site-menu main-menu js-clone-nav ml-auto d-none d-lg-block">
                                <li class="has-children">
                                    <a href="#" class="nav-link">Главная <v-icon>mdi-menu</v-icon></a>
                                    <ul class="dropdown arrow-top">
                                        <li><a href="#mission-section" class="nav-link">Биз жөнүндө</a></li>
                                        <li><a href="#press-section" class="nav-link">Соңку маалыматтар</a></li>
                                        <li><a href="#testimonials-section" class="nav-link">Отзывдар</a></li>
                                    </ul>
                                </li>
                                <li><a href="#english-section" class="nav-link">English language</a></li>
                                <li><a href="#exam-section" class="nav-link">ЖРТ</a></li>
                                <li><a href="#actual-section" class="nav-link">Актуладуу маалыматтар</a></li>
                                <li><a href="#faq-section" class="nav-link">Служба поддержки</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div class="toggle-button d-inline-block d-lg-none"><a href="#" class="site-menu-toggle py-5 js-menu-toggle text-black"><span class="icon-menu h3"><v-icon>mdi-menu</v-icon></span></a></div>
                    </div>
                    </div>
                </header>
            </div>
    </div>
</template>
<script>
import { userService } from '../../_services/user.service';
export default {
    data: () => ({
    }),
    computed: {
        loggedIn () {
            return this.$store.state.account.user;
        },
    },
    created () {},
    methods: {
        logOut() {
            userService.logout();
        }
    }
}
</script>
<style lang="scss" scoped>
</style>